




































import { Component, Vue, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import Footer from '@/components/Footer/Footer.vue';

import HeaderAThousandRedaction from './components/HeaderAThousandRedaction/index.vue';
import NoteAThousandRedactionLoading from './NoteAThousandRedactionLoading.vue';
import RedactionPreview from '../../components/RedactionPreview/RedactionPreview.vue';

import RedactionService from '@/services/Redaction/RedactionService';

import { Redaction as IRedaction } from '../../interfaces/IRedactionPreview';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    HeaderAThousandRedaction,
    NoteAThousandRedactionLoading,
    RedactionPreview,
    FeedbackUser,
    Footer
  }
})
export default class NoteAThousandRedaction extends Vue {
  private isActiveCorrection = true;
  private isLoadingRedaction = true;

  private redaction!: IRedaction | null;

  private RedactionService = new RedactionService();

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  @Watch('$route.params.redactionId', {
    immediate: true
  })
  async loadData() {
    this.getRedaction();

    this.isActiveCorrection = true;
  }

  async getRedaction() {
    try {
      this.isLoadingRedaction = true;
      const { redactionId } = this.$route.params;
      this.redaction = null;

      const response = await this.RedactionService.getCorrectionRedaction(Number(redactionId));

      this.redaction = response || null;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o conteúdo da redação.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoadingRedaction = false;
    }
  }

  setOptionMenu(value: boolean) {
    this.isActiveCorrection = value;
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Redação', to: '/redacao' },
      { title: 'Nota mil', to: null }
    ]);
  }
}
