




























































import { Component, Prop, Vue } from 'vue-property-decorator';

import * as Annotorious from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';

import LishtBoxImage from '@/mixins/LightBoxImage';

import GoBack from '@/components/GoBack/index.vue';

import { Comments as IComments } from '../../interfaces/IRedactionPreview';

@Component({
  components: {
    GoBack
  },
  mixins: [LishtBoxImage]
})
export default class RedactionPreview extends Vue {
  @Prop() isActiveCorrection!: boolean;
  @Prop() redactionObservation!: string;
  @Prop() redactionContent!: string;
  @Prop() redactionComments!: Array<IComments>;
  @Prop() redactionURL!: any;

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  hasContentHTML(content: string) {
    if (content === '<p><br></p>') {
      return false;
    }

    return !!content;
  }

  get setImageRedaction() {
    if (!this.redactionURL) return '';

    return `https://explicae.com.br/app/${this.redactionURL}`;
  }

  setCommentsRedaction() {
    const imgRedaction: any = document.getElementById('img-redaction');

    if (this.redactionURL) {
      const config = {
        image: imgRedaction,
        readOnly: true
      };

      const anno = Annotorious.init(config);
      const annotations: Array<IComments> | undefined = this.redactionComments;

      const width = imgRedaction?.naturalWidth;
      const height = imgRedaction?.naturalHeight;

      if (annotations) {
        annotations.forEach((annotation: IComments, index) => {
          const x = this.calculatePixels(Number(annotation.x), width);
          const y = this.calculatePixels(Number(annotation.y), height);
          const w = this.calculatePixels(Number(annotation.width), width);
          const h = this.calculatePixels(Number(annotation.height), height);

          anno.addAnnotation({
            '@context': 'http://www.w3.org/ns/anno.jsonld',
            id: index,
            type: 'Annotation',
            body: [
              {
                type: 'TextualBody',
                value: annotation.text
              }
            ],
            target: {
              selector: {
                type: 'FragmentSelector',
                conformsTo: 'http://www.w3.org/TR/media-frags/',
                value: `xywh=pixel:${x},${y},${w},${h}`
              }
            }
          });
        });
      }

      anno.on('mouseEnterAnnotation', (annotation: any) => {
        anno.selectAnnotation(annotation);
        anno.setVisible(true);
      });

      anno.on('mouseLeaveAnnotation', () => {
        anno.cancelSelected();
      });
    }
  }

  calculatePixels(percentage: number, pixels: number) {
    return pixels * percentage;
  }
}
